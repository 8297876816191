import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "nateShoemaker",
  "categories": ["ruby", "lambda"],
  "date": "2020-07-29",
  "path": "/blog/aws-lambda-ship-it",
  "summary": "Package and ship to AWS Lambda.",
  "title": "Ruby on AWS Lambda: Package & Ship it",
  "image": "./ship-it.png",
  "seo": {
    "og": {
      "type": "article",
      "title": "Ruby on AWS Lambda: Package & Ship it",
      "description": "Package and ship to AWS Lambda."
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`This article is part of our Ruby on AWS Lambda series. A recent project had us migrating an existing pdf document processing system from Rails/Sidekiq to AWS Lambda. The processing includes OCR, creating preview images, splicing the pdf, and more. Moving to Lambda reduced processing time by 300% in some cases; parallelization for the win!`}</em></p>
    <p><em parentName="p">{`This series will serve less as a step-by-step process to get OCR serverless infrastructure up and running and more of a highlight reel of our "Aha!" moments. In part one, we talked about creating an AWS Lambda Layer with Docker. In part two, we chatted about architecture. Here in part three, we'll go through some strategies surrounding deployment. Check out the other posts in the series:`}</em></p>
    <ul>
      <li parentName="ul"><em parentName="li"><a parentName="em" {...{
            "href": "/blog/lambda-layer-dependencies"
          }}>{`Layer Dependencies`}</a></em></li>
      <li parentName="ul"><em parentName="li"><a parentName="em" {...{
            "href": "/blog/lambda-planning-and-architecture"
          }}>{`Planning & Architecting`}</a></em></li>
      <li parentName="ul"><em parentName="li"><a parentName="em" {...{
            "href": "/blog/lambda-and-active-storage"
          }}>{`Integrating with Active Storage`}</a></em></li>
    </ul>
    <p>{`Deploying to Lambda gets more complicated as the application grows. There is a big difference between deploying a single `}<inlineCode parentName="p">{`hello world`}</inlineCode>{` function with zero dependencies, and deploying multiple functions in multiple languages along with dependencies in a Lambda Layer. We discovered some useful things along the way while making that transition. Let's dive in.`}</p>
    <h2>{`Function naming conventions and environment variables`}</h2>
    <p>{`We followed a straightforward convention for differentiating between function names and their environments:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`{function_name}_{environment}`}</code></pre></div>
    <p>{`Given our three Rails environments (`}<inlineCode parentName="p">{`development`}</inlineCode>{`, `}<inlineCode parentName="p">{`staging`}</inlineCode>{`, and `}<inlineCode parentName="p">{`production`}</inlineCode>{`) and a function name of `}<inlineCode parentName="p">{`pdf_processor`}</inlineCode>{`, we'd have three separate functions: `}<inlineCode parentName="p">{`pdf_processor_development`}</inlineCode>{`, `}<inlineCode parentName="p">{`pdf_processor_staging`}</inlineCode>{`, and `}<inlineCode parentName="p">{`pdf_processor_production`}</inlineCode>{`. Adding more functions may seem a bit overwhelming in terms of function count, but the clear distinctions between functions and their environments allow for isolated development, testing, and debugging.`}</p>
    <p>{`Along with other normal environment variables that the functions use, we added a `}<inlineCode parentName="p">{`RUBY_ENV`}</inlineCode>{` environment variable that was either `}<inlineCode parentName="p">{`development`}</inlineCode>{`, `}<inlineCode parentName="p">{`staging`}</inlineCode>{`, or `}<inlineCode parentName="p">{`production`}</inlineCode>{`. This is primarily used for name-spacing S3 buckets, DynamoDB tables, and calling other lambda functions. This decision played out well because we now have isolation in how we use other systems and services. This also makes reading logs and tracking performance metrics a lot easier.`}</p>
    <h2>{`Instrumentation`}</h2>
    <p>{`Instrumentation refers to the measure of a product's performance, to diagnose errors, and to write trace information. Early on in development, we added instrumentation to gain insight into how the bulk of the processing work performs.`}</p>
    <p>{`Here’s our implementation:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# instrument.rb`}</span>{`
 
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`module`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Instrument`}</span>{`
 `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`instrument`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`tag`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
   start `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Process`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`clock_gettime`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Process`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`CLOCK_MONOTONIC`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` puts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"--> `}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`tag`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{` took `}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token constant"
              }}>{`Process`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`clock_gettime`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token constant"
              }}>{`Process`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "token constant"
              }}>{`CLOCK_MONOTONIC`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`-`}</span>{` start`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`*`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`1000`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`round`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`ms"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Then it is used like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instrument'`}</span>{`
 
i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` instrument`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'doing some work'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
do_the_work`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`call`}</code></pre></div>
    <p>{`The log would look like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`--> doing some work took 300ms`}</code></pre></div>
    <p>{`The `}<inlineCode parentName="p">{`start`}</inlineCode>{` variable gets caught in a closure and is available for the Proc that is returned. The semi-cryptic looking `}<inlineCode parentName="p">{`Process.clock_gettime`}</inlineCode>{` is used to read from the OS clock, and is more accurate than Ruby's `}<inlineCode parentName="p">{`Time`}</inlineCode>{` class.`}</p>
    <h2>{`Serverless`}</h2>
    <p>{`When we first started building on Lambda, we `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/lambda/latest/dg/ruby-package.html"
      }}>{`deployed the AWS way`}</a>{`: zip the code up and ship it via the AWS CLI. This was fine in the beginning, but it left a lot to be desired. It puts the responsibility on the developer to know about the deployment process, something that should be handled by configuration. That added responsibility also detracted from a deployment being deterministic. If you missed a small detail, a deployment, and thus the function itself, could be botched. We don't zip and deploy our Rails apps up manually, and we shouldn't have to do that with our serverless functions either!`}</p>
    <p>{`In comes `}<a parentName="p" {...{
        "href": "https://www.serverless.com/"
      }}>{`Serverless Framework`}</a>{`. Everything we love about configuration and deterministic deployments are handled by Serverless. All configuration is written in YAML and is concise, intuitive, and incredibly powerful.. Environment variables, runtime specification, memory configuration, AWS roles, and layer versioning can all be done in a single YAML file. These features became even more valuable when we rewrote our vent function in JavaScript. We  now have a separate serverless config for each lambda function that allows us to achieve true language isolation. If you'd like to learn more about using Serverless, take a look at `}<a parentName="p" {...{
        "href": "https://www.serverless.com/framework/docs/providers/aws/guide/quick-start/"
      }}>{`their AWS quickstart guide`}</a>{`.`}</p>
    <p>{`Shipping a Lambda application doesn’t have to be difficult. I hope what we’ve learned has helped you!`}</p>
    {
      /* Check out the next post in the series where we figure out how to integrate with Active Storage (LINK TO ARTICLE HERE PLS) */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      